$licorice:            #1a1110;
$smoke:               #F9F9F9;
$white-1:             #FFFEFE;
$white :              #FFFFFF;
$ultraViolet: #340458;
$lavender:            #7A5D8E;
$onyx:                #353839;
$color-social:        $onyx;
$gray-92:             #EBEBEB;

$neon-violet:         #542EFF;
$neon-blue:           #6B48FF;
$bluish-purple:       #8D49F7;
$ice-climber:         #1EE3CF;
$blue-navy:           #0D3F67;
$black-violet:        #1E1926;

//START: OVERRIDE BOOTSTRAP VARIABLES
$nav-link-padding-y:     .3rem;
$navbar-light-color: $onyx;
$navbar-light-toggler-border-color: $onyx;
$dropdown-link-hover-bg: $onyx;
$carousel-control-width: 3em;
$gray-100:              #F9F9F9;
$gray-800:              #353a3f;
$gray-900:              #0e0f11;
$enable-rounded:         false;
$height-navbar-brand:    5rem;
$height-navbar-brand-lg: 7rem;
$color-facebook:         #4267B2;
$color-google-plus:      #DB4437;
$color-twitter:          #00ACEE;
$color-pinterest:        #C8232C;
$z-index-100:            100;
$zindex-dropdown:        1001;
$primary:                $neon-violet;
$secondary:				 $neon-blue;
$info:				 	 $ultraViolet;
$nav-pills-link-active-bg: $ultraViolet;
$highlighter-default:    #17A2B8;
$caret-width:            0;
$font-size-base:         1rem;
$font-weight-normal:     500;
$font-family-sans-serif:      "Hanken Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-monospace:       "Anonymous Pro", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1140px
);

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: ($spacer * .25),    //4px
        2: ($spacer * .5),     //8px
        3: $spacer,            //16px
        4: ($spacer * 1.5),    //24px
        5: ($spacer * 3),      //48px
        6: ($spacer * 4),      //64px
        7: ($spacer * 5),      //80px
        8: ($spacer * 6.25),   //100px
        9: ($spacer * 7.5),    //120px
        10: ($spacer * 9.375)  //150px
);
//END: OVERRIDE BOOTSTRAP VARIABLES