.header {
  @extend .fixed-top;
  background-color: transparent;
  background-image: linear-gradient(130deg, $primary 0%, $secondary 50%, $bluish-purple 100%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
  a:hover {
    text-decoration: none;
    color: white;
  }
  &__container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @include media-breakpoint-up(lg) {
    position: fixed;
  }

  @include media-breakpoint-down(lg) {
    .buttons {
      padding: 0.5rem 1rem;
    }
  }
}
