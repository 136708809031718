.image {
  $self: &;

  @extend .mb-3;
  display:flex;
  justify-content: center;
  position: relative;

  &__container-center {
    position: absolute;
    top: 0;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    color: $white;
  }

  &__title{
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: bold;
  }

  &__subtitle{
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: bold;
  }

  &__description {
    font-size: 1rem;
    @extend .mb-4;
    @extend .mt-2;
  }

  &_filter_blur{
    #{ $self }__image{
      filter: blur(2px);
    }
  }

  &_filter_dark{
    #{ $self }__image{
      filter: brightness(50%);
    }
  }

  &_filter_blur-and-dark{
    #{ $self }__image{
      filter: blur(2px) brightness(50%);
    }
  }

  @include media-breakpoint-up(md) {
    &__container-center{
      max-width: 80%;
    }

    &__title{
      font-size: 1.625rem;
      line-height: 2.1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &__container-center{
      max-width: 768px;
    }
  }
}