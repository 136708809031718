.quote-section {

  .image-wrapper {
    width: 110px;
    height: 110px;
    border-width: 1px;
    border-color: $primary;
    border-radius: 50%;
    overflow: hidden;
    border-style: solid;
  }

  .fa-quote-left, .fa-quote-right {
    position: absolute;
    font-size: 120px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
    color: #6B48FF17;
  }

  .fa-quote-left {
    left: -30px;
    @include media-breakpoint-down(lg) {
      top: 0;
    }
  }

  .fa-quote-right {
    right: -30px;
    @include media-breakpoint-down(lg) {
      top: 100%;
    }
  }
  .text-content {
    font-size: 1.18rem;
    @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
    }
  }
}