.quote-slider{
  $self: &;

  background-color: $ultraViolet;
  color: white;
  padding-top:3rem;
  padding-bottom: 3rem;

  &__wrap{
    margin-left: auto;
    margin-right: auto;
  }

  &__icon{
    width: 34px;
    margin: 0 auto;
  }

  &__lead{
    color: $neon-blue;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.1rem;
    margin-bottom: 1rem;
  }

  &__content{
    font-size: 1rem;
    line-height: 1.55rem;
    margin-bottom: 2rem;
  }

  &__person{
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__company{
    justify-content: center;
    align-items: center;
  }

  &__company-name{
    font-size: 2rem;
    font-weight: bold;
  }

  &__person-image-wrap{
    height: 64px;
    width: 64px;
    border-radius: 50%;
    border: 2px solid $neon-blue;
    display:flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
  }

  &__person-image{
    min-height: 100%;
  }

  &__person-icon{
    max-width: 64px;
  }

  &__person-details-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* slider styles */
  .slick-track{
    display: flex;
    align-items: center;
  }

  &__slider-dots{
    display:flex;
    justify-content: center;
    margin-top: 1rem;
    color: $smoke;
    font-size: 2rem;
    padding-left: 4.4rem;

    li{
      flex-basis: 2rem;
    }

    .slick-active{
      color: $neon-blue;
    }

    button{
      display:none;
    }
  }

  /* quote__light modifier */
  &__light{
    background-color: $smoke;
    color: $onyx;

    #{ $self }__icon{
      color: $ultraViolet;
    }

    #{ $self }__lead{
      color: $ultraViolet;
    }

    #{ $self }__person-image-wrap{
      border-color: $ultraViolet;
    }

    #{ $self }__company-name{
      color: $ultraViolet;
    }

    #{ $self }__link{
      background-color: $onyx;
      color: $white;
      &:after {
        border-bottom: 40px solid #4a4c4d;
      }
      span {
        &:after {
          border-bottom: 1px solid $white;
        }
      }
    }

    #{ $self }__slider-dots{
      color: $ultraViolet;
    }
  }

  /* quote__dark modifier */
  &__dark{
    background-color: $onyx;
    color: white;

    #{ $self }__icon{
      color: $neon-blue;
    }

    #{ $self }__lead{
      color: $neon-blue;
    }

    #{ $self }__person-image-wrap{
      border-color: $neon-blue;
    }
  }

  &__person-company-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include media-breakpoint-up(md){

    &__lead{
      font-size: 2.5rem;
      line-height: 3.45rem;
    }

    &__icon{
      width: auto;
    }

    &__content{
      font-size: 1.25rem;
      line-height: 1.625rem;
    }

    &__person {
      margin-bottom: 0;
      max-width: 50%;
    }

    &__company{
      max-width: 50%;
    }

  }
}

