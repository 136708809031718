.pagination{
  $self: &;

}

.pager{
  $self: &;

  margin: 2rem 0;

  &__list{
    list-style: none;
    display:flex;
    flex-direction: row;
    padding: 0;
    justify-content: center;
  }

  &__list-item{
    min-width: 120px;
  }

  &__list-item_current{
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

