.find-app-card{

  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
  overflow: hidden;
  max-width: 420px;

  &__header{
    @extend .card-header;
  }

  &__info-list{
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: wrap;
  }

  &__info-item{
    margin-right: 1.25rem;
  }

  &__body{
    @extend .card-body;
    padding-right: 0;
    padding-left: 0;
  }

  &__footer{
  }

  &__title{
    @extend .card-title;
    color: $ultraViolet;
    font-size: 1.25rem;
    font-weight: bold;
  }

  &__text{
    @extend .card-text;
  }

  &__logo-wrap{
    height: 240px;
    display: flex;
    align-items: center;
  }

  &__logo{
    @extend .card-img-top;
    max-height: 100%;
    color: $ultraViolet;
    font-size: 2rem;
    font-weight: bold;
  }
}