.modals {
  background-color: rgba(26,17,16,.5); //$licorce to rgb

  &__dialog {
    top: 5rem;

    @include media-breakpoint-up(lg) {
      top: 3rem;
    }
  }

  &__title {
    color: $ultraViolet;
    font-size: 1.7rem;
    font-weight: bold;
    margin: 0;
    padding: 1rem;
  }

  &__close {
    background-color: $white;
    border: 0;
    height: 64px;
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;
  }

  &__loading-overlay{
    @extend .loading;

    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(255,255,255,.9);
    z-index: 1;
  }
}