.partner-profile{
  $self: &;

  padding-top: 5rem;

  &__top-row{
    margin-bottom: 2rem;
  }

  &__title-services{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__logo{
    @extend .img-fluid;
    margin-bottom: 2rem;
  }

  &__title{
    color: $ultraViolet;
    font-size: 3rem;
    font-weight: bold;
  }

  &__info{
    margin-bottom: 2rem;
    order: 5;
  }

  &__info-title{
    color: $ultraViolet;
    font-weight: bold;
    font-size: 1.625rem;
  }

  &__description{
    font-size: 1.2rem;
    margin-bottom: 2rem;
    order: 1;
  }

  @include media-breakpoint-up(md) {
    &__top-row{
      flex-direction: row-reverse;
    }
  }

  @include media-breakpoint-up(lg) {
    &__description{
      order: unset;
    }

    &__info{
      order: unset;
    }
  }
}