body {
  padding-top: 4.5rem;
  @include media-breakpoint-up(md) {
    padding-top: 7rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 7.9rem;
  }
  @include media-breakpoint-down(md){
    font-size: 0.95rem;
  }
}

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  color: $gray-800;
}

.h1, h1 {
  @include media-breakpoint-up(lg) {
    font-size: 3.05rem;
  }
  font-size: 2.3rem;
  font-weight: 800;
}

.h2, h2 {
  @include media-breakpoint-up(lg) {
    font-size: 2.44rem;
  }
  font-size: 1.85rem;
  font-weight: 800;
}

.h3, h3 {
  @include media-breakpoint-up(lg) {
    font-size: 1.95rem;
  }
  font-size: 1.48rem;
  font-weight: 800;
}

.h4, h4 {
  @include media-breakpoint-up(lg) {
    font-size: 1.56rem;
  }
  font-size: 1.18rem;
  font-weight: 800;
}

.h5, h5 {
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
  font-size: 1.09rem;
  font-weight: 800;
}

.h6, h6 {
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
  font-size: 0.95rem;
  font-weight: 800;
}

p {
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
  font-size: 0.95rem;
}

/*Fix to tinymce*/
#tinymce{
  padding-top: 0;
}

a {
  text-decoration: none;
}
.top-shape-wrapper {
  background-image: linear-gradient(130deg, $primary 0%, $secondary 50%, $bluish-purple 100%);
}

.section-gradient {
  background-image: linear-gradient(130deg, $primary 0%, $secondary 50%, $bluish-purple 100%);
}

.section-grey {
  background-color: #F2F4F6;
}

.text-gradient {
  background: -webkit-linear-gradient(60deg, $primary 0%, $secondary 50.5%, $bluish-purple 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.letter-spacing-small {
  letter-spacing: 2px;
}

#cookieAlert {
  bottom: 0;
  margin-bottom: 0;
  border-radius: 0;
  position: fixed;
  z-index: $zindex-sticky;
  width: 100%;
}


#searchContainer{
  background-color: $gray-300;

  .typeahead{
    top: 35px;
    width: 100%;
  }
}

// START: Return to top button
#return-to-top {
  background: $black;
  border: none;
  bottom: 10px;
  cursor: pointer;
  display: none;
  height: 35px;
  opacity: 0.8;
  position: fixed;
  right: 10px;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 35px;

  i {
    color: $white;
    margin: 0;
    position: relative;
    transition: all 0.3s ease;
  }

  &:hover {
    opacity: 1;
    i {
      top: -3px;
    }
  }

  @include btn-a-style;
}
// END: Return to top button