.buttons {
  background-color: $secondary;
  color: $white;
  border-radius: 0.536rem;
  padding: 0.65rem 1.3rem;
  display: inline-block;
  text-align: center;
  transition: background-color .3s,color .3s;
  font-size: 1rem;
  line-height: 1.3em;
  border: 1px solid $secondary;
  &:hover,&:focus {
    color: white;
    background-color: #4632da;
  }
  .login-user {
    max-width: 3rem;
  }
  &__alt {
    background-color: $white;
    color: $primary;
    border: 1px solid $white;
    &:hover,&:focus {
      background-color: $white;
      border: 1px solid $primary;
      color: $primary;
      font-weight: 600;
    }
  }
}