.partner-portfolio{
  $self: &;

  padding-top: 3rem;
  padding-bottom: 3rem;

  &:nth-child(even) {
    background: $smoke;
  }

  &__info{
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &__title{
    font-size: 1.625rem;
    line-height: 2.125rem;
    margin-bottom: 1.625rem;
    color: $ultraViolet;
    font-weight: bold;
  }

  &__description{
    font-size: 1rem;
    line-height: 1.625rem;
    margin-bottom: 1.625rem;
  }

  &__link{
    align-self: flex-start;
  }

  &__image{
    @extend .img-fluid;
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(md) {
    &:nth-child(even) .row{
      flex-direction: row-reverse;
    }

    &__title{
      font-size: 2.5rem;
      line-height: 3.375rem;
    }

    &__description{
      font-size: 1.25rem;
      line-height: 1.625rem;
    }

    &__image{
      align-self: center;
      margin-bottom: 0;
    }

  }
}