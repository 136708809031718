.card-slider {
  $self: &;

  background-color: $smoke;
  width: 100%;
  padding: 3rem 0;

  &__container{
    overflow:hidden;
  }

  &__controls{
    z-index: 1;
    background: $smoke;
  }

  &__title{
    @extend .h3;
    color: $ultraViolet;
    line-height: $line-height-base;
  }

  &__slides-wrap{
    margin-bottom: 2rem;
  }

  &__slides{
    max-width: calc(100% - 20px);
  }

  &__image-wrap{
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;
  }

  &__content{
    padding: 0 1rem;
  }

  &__slides .slick-track,
  &__slides .slick-list{
    overflow:visible;
  }

  &__slide{
    margin-right: 15px;
    position: relative;
    opacity: .3;

    &.slick-active {
      opacity: 1;
    }
  }

  &__slide-title{
    text-transform: uppercase;
    color: $ultraViolet;
  }

  &__slide-description{
    font-size: $font-size-sm;
  }

  &__navigation--bottom{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }

  &__progress-bar{
    display: block;
    width: calc(100% - 84px);
    height: 4px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #CCC;
    background-image: linear-gradient(to right, $ultraViolet, $ultraViolet);
    background-repeat: no-repeat;
    background-size: 0 100%;  transition: background-size .4s ease-in-out;
  }

  &__navigation-links{
    width: 68px;
  }

  &__navigation-link{
    display: inline-block;
    width: 30px;
  }

  &__navigation-link-prev{
    margin-right: .25rem;
  }

  &__play-icon-wrap{
    height: 255px;
    background-image: radial-gradient(circle, #340458b8 0, #340458 60%);
    margin-bottom: 1rem;
    position: relative;
  }

  &__play-icon{
    @extend .video-play-icon;
  }

  /* Text card modifier */
  &--text{
    #{ $self }__slide{
      padding-top: 1rem;

      &:before{
        content: '';
        display: block;
        border-top: 3px solid $neon-blue;
        position: absolute;
        width: 40px;
        top: 0;
        left: 0;
      }
    }

    #{ $self }__content{
      padding: 0;
    }

  }
}

@include media-breakpoint-up(sm) {
  .card-slider {

    &__progress-bar{
      width: calc(100% - 152px);
    }

    &__navigation-links{
      width: 108px;
    }

    &__navigation-link{
      width: 40px;
    }

    &__navigation-link-prev{
      margin-right: .5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card-slider{
    padding: 6rem 0;

    &__title{
      font-size: $h1-font-size;
    }

    &__controls{
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      justify-content: space-between;
    }

    &__progress-bar {
      display: block;
      width: 100%;
    }

    &__navigation-link{
      width: 40px;
    }
  }
}
