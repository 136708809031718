.banner{
  $self: &;

  flex-direction: column;
  justify-content: flex-end;
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: white;
  position: relative;
  overflow: hidden;
  max-height: calc(100vh - 108px);


  /* Sizes */
  &_size_tall{
    min-height: 505px;
  }

  &_size_short{
    min-height: 370px;
  }

  &_size_taller{
    height: 505px;
    @include media-breakpoint-up(lg) {
      height: 700px;
      .banner__content_wrapper {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }
    }
  }

  &_size_shorter{
    height: 370px;
  }
  /* End Sizes */

  &_type_picture {
    .banner_filter_blur{
      filter: blur(2px);
    }
    .banner_filter_dark{
      filter: brightness(50%);
    }
    .banner_filter_blur-and-dark{
      filter: blur(2px) brightness(50%);
    }
    picture {
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: cover;
      width: 100%;
      min-width: 100%;
      height: 100%;
      min-height: 100%;
      object-position: 50%;
    }
  }

  &_style_solid-colour{
    background-color: $ultraViolet;
  }

  &_style_solid-colour-inset{
    background-color: $ultraViolet;
    justify-content: flex-start;
    margin-bottom: 200px;
    overflow: visible;
    padding-top: 2rem;
    padding-bottom: 0;
  }

  &_filter_blur{
    #{ $self }__background-image{
      filter: blur(2px);
    }
  }

  &_filter_dark{
    #{ $self }__background-image{
      filter: brightness(50%);
    }
  }

  &_filter_blur-and-dark{
    #{ $self }__background-image{
      filter: blur(2px) brightness(50%);
    }
  }

  &__background-image{
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__inset-image{
    margin-top: 2rem;
    width: 350px;
  }

  &__title{
    font-size: 2rem;
  }

  &__content_wrapper{
    position: absolute;
    left: 0;
    bottom: 4rem;
    @include media-breakpoint-up(xl) {
      left: 80px;
    }
  }

  &__content{
    font-size: 1rem;
    a{
      color: $neon-blue;
    }
  }

  @include media-breakpoint-up(md) {
    &_size_tall{
      justify-content: center;
    }
  }

  @include media-breakpoint-up(lg) {
    &__title {
      font-size: 3rem;
    }

    &_size_tall{
      height: 700px;
    }
  }

  @include media-breakpoint-up(xl) {
    padding-left: 80px;
    padding-right: 80px;

    &__title {
      font-size: 4rem;
    }
  }
}


