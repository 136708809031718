.video-component{
   $self: &;

   padding: 4rem 0;
   width: 100%;

   &__border{
      background: transparent linear-gradient(213deg, #F9F9F9 0%, #08FACC 100%) 0% 0% no-repeat padding-box;
      padding: 10px;
      margin-right: 0;
      margin-left: 0;
   }

   .col-lg-5{
      display:flex;
      background: $smoke;
   }

   &__image-wrap{
      width: calc(100% + 40px);
      margin-left: -15px;
      margin-top: -20px;
      position: relative;
      height: 100%;

      &:hover {
         #{ $self }__play-icon {
            background-color: rgba(8, 250, 204, .5);
         }
      }
   }

   &__content-wrap{
      width: calc(100% + 40px);
      margin-left: -25px;
      background-color: $smoke;
      padding: 2rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
   }

   &__video-background{
      background-image: radial-gradient(circle, #340458b8 0, #340458 60%);
      min-height: 320px;
      height: calc(100% + 20px);
      width: 100%;
      display:flex;
   }

   &__play-icon{
      @extend .video-play-icon;
   }

   &__title{
      font-weight: bold;
      color: $ultraViolet;
      font-size: 1.25rem;
      line-height: 1.625rem;
   }

   &__subtitle{
      font-weight: bold;
      color: $ultraViolet;
      font-size: .8rem;
      line-height: 1.25rem;
   }

   &__description{
      font-size: .8rem;
      line-height: 1.25rem;
   }

   &__modal-body{
      min-height: 200px;
      > * {
         max-width: 100%;
      }
   }

   &__wistia-padding{
      padding:56.25% 0 0 0;
      position:relative;
   }

   &__wistia-wrapper{
      height:100%;
      left:0;
      position:absolute;
      top:0;
      width:100%;
   }

   &__wistia-embed{
      height:100%;
      position:relative;
      width:100%
   }

   &__wistia-swatch{
      height:100%;
      left:0;
      opacity:0;
      overflow:hidden;
      position:absolute;
      top:0;
      transition:opacity 200ms;
      width:100%;
   }

   &__wistia-swatch-image{
      filter:blur(5px);
      height:100%;
      object-fit:contain;
      width:100%;
   }

   /* Style Variants */
   &_style_pattern{
      @extend .pattern-background;
   }

   &_style_dark{
      background: $ultraViolet;
   }
   /* End Style Variants */

   @include media-breakpoint-up(sm) {
      &__border{
         max-width: 600px;
         margin-right: auto;
         margin-left: auto;
      }
   }

   @include media-breakpoint-up(md) {

      &__title{
         font-size: 1.625rem;
         line-height: 2.125rem;
      }

      &__subtitle{
         font-size: 1rem;
         line-height: 1.25rem;
      }

      &__description{
         font-size: 1rem;
         line-height: 1.55rem;
      }

      &__image-wrap{
         margin-left: -15px;
      }

      &__content-wrap{
         margin-left: -25px;
         margin-right: -15px;
         height: 100%;
         width: calc(100% + 50px);
      }
   }

   @include media-breakpoint-up(lg) {

      &__border {
         max-width: unset;
      }

      &__image-wrap{
         width: calc(100% + 30px);
      }

      &__content-wrap{
         width: calc(100% + 30px);
         height: calc(100% + 20px);
         margin-left: -15px;
      }
   }

}