.platform-feature {
   $self: &;

   &__description-row{
      padding-top: 3rem;
      padding-bottom: 3rem;

      &_dark{
         background-color: $ultraViolet;
         color:white;
      }
   }

   &__title{
      color: #340458;
      font-size: 1.625rem;
      font-weight: bold;
      margin-bottom: 1.625rem;

      &_dark{
         color:white;
      }
   }

   &__image{
      margin-top: 3rem;
   }

   &__pagination{
      flex-direction: column;
      align-items: center;
      padding-top: 5rem;
      padding-bottom: 5rem;
      background-color: $smoke;
   }

   &__pagination-title{
      color: $ultraViolet;
      font-size: 1.625rem;
      margin-bottom: 1.625rem;
      font-weight: bold;
      text-align: center;
      padding: 0 2rem;
   }

   &__pagination-controls{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      min-height: 120px;
      margin: 0 auto;
      max-width: 620px;
   }

   &-modifier {
      #{ $self }__element {
      }
   }

   @include media-breakpoint-up(sm) {
   }
   @include media-breakpoint-up(md) {
      padding-top: 4rem;
   }
   @include media-breakpoint-up(lg) {
   }
   @include media-breakpoint-up(xl) {
   }

}