.breadcrumb {
   $self: &;

   display: flex;
   flex-wrap: wrap;
   padding: .75rem 1rem;
   margin-bottom: 0;
   background-color: #e9ecef;

   &__list{
      list-style: none;
      margin-bottom: 0;
   }

   &__item {
      display: inline-block;
      padding-left: $breadcrumb-padding-x;

      &::before {
         display: inline-block; // Suppress underlining of the separator in modern browsers
         padding-right: $breadcrumb-padding-x;
         color: $breadcrumb-divider-color;
         content: $breadcrumb-divider;
      }

      &:first-child {
         &::before {
            content: '';
         }
      }

      &.active {
         color: $breadcrumb-active-color;
      }
   }

   @include media-breakpoint-up(sm) {
   }
   @include media-breakpoint-up(md) {
   }
   @include media-breakpoint-up(lg) {
   }
   @include media-breakpoint-up(xl) {
   }

}