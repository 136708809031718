.call-to-action {
  $self: &;

  text-align: center;
  background-color: $ultraViolet;
  color: $smoke;
  padding-top: 6rem;
  padding-bottom: 6rem;

  &_light{
    background-color: $smoke;
    color: $ultraViolet;
  }

  &_dark{
    background-color: $onyx;
  }

  &__title{
    font-size: 1.25rem;
    line-height: 1.625rem;
    font-weight: bold;
  }

  &__content{
    min-height: 1px;
  }

  @include media-breakpoint-up(sm) {
    &__title{
      font-size: 1.625rem;
      line-height: 2.125rem;
    }
  }

  @include media-breakpoint-up(md) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-up(xl) {}
}