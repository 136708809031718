.usps {
   $self: &;

   display: flex;
   justify-content: center;
   width: 100%;
   padding-right: 1rem;
   padding-left: 1rem;

   &__wrap{
      max-width: 1260px;
      padding-right: 1rem;
      padding-left: 1rem;
   }

   &__content{
      padding-top: 3rem;
      padding-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;
   }

   &__title{
      font-size: 1.625rem;
      font-weight: bold;
      color: $ultraViolet;
      margin-bottom: 1.625rem;
   }

   &__image-wrap{
      margin-bottom: 2rem;
      justify-content: center;
      align-items: center;
   }

   @include media-breakpoint-up(sm) {

      &__title{
         max-width: 80%;
         font-size: 2rem;
      }

      &__description{
         max-width: 80%;
      }
   }

   @include media-breakpoint-up(md) {

      #{ $self }__content {
         padding-top: 6rem;
      }

      #{ $self }__title {
         font-size: 2.5rem;
         max-width: 90%;
      }

      #{ $self }__description {
         font-size: 1.25rem;
         margin-bottom: 2.625rem;
         max-width: 90%;
      }
   }

   @include media-breakpoint-up(lg) {
      padding-right: 5rem;
      padding-left: 5rem;
   }
}