.find-partner-app{
  $self: &;

  padding-top: 3rem;

  &__headings{
    margin-bottom: 2rem;
  }

  &__title{
    color: #340458;
    font-weight: 700;
    font-size: 1.625rem;
  }

  &__description{
    font-size: 1rem;
  }

  &__filter-container{
    margin-bottom: 3rem;
  }

  &__filter{
    margin-bottom: 1rem;
  }

}