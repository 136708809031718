.pricing {

  $self: &;
  padding-top: 6rem;
  padding-bottom: 6rem;

  &__larger-padding {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &_dark{
    background-color: $ultraViolet;
    color: $smoke;
  }

  &_grey{
    background-color: $smoke;
    color: black;
  }

  &_white{
    background-color: white;
    color: black;
  }

  &__title{
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: bold;
    color: $ultraViolet;
  }

  &__title_neon{
    color: $neon-blue;
  }

  &__title_small{
    font-size: 2rem;
    line-height: 3rem;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.2px;
  }

  &__description_medium {
    font-size: 0.95rem;
    line-height: 2.4rem;
  }

  &__description_big {
    font-size: 1.65rem;
    line-height: 2rem;
  }

  &__description_bigger {
    font-size: 1.15rem;
    line-height: 2rem;
  }

  &__subtitle {
    font-size: 1.1rem;
    line-height: 1.875rem;
  }

  &__fact {
    font-size: 1.5rem;
    line-height: 1.675rem;
    font-weight: bold;
    del {
      text-decoration-color: $neon-blue;
    }
  }

  &__colored {
    font-weight: bold;
    color: $ultraViolet;
  }

  &__content{
    min-height: 1px;
  }

  &__wrapper-gradient {
    color: $smoke;
    padding: 10px;
    display: inline-block;
    position:relative;
    border-radius: 8px;
    z-index:0;
    &:before {
      content:"";
      position:absolute;
      z-index:-1;
      top:0;
      left:0;
      right:0;
      bottom:0;
      padding: 2px;
      border-radius: 8px;
      background: rgb(246,9,254);
      background: linear-gradient(0deg, rgba(246,9,254,1) 0%, rgba(136,122,231,1) 25%, rgba(108,149,225,1) 50%, rgba(50,207,214,1) 75%, rgba(14,244,206,1) 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    .fa-tag {
      width: 32px;
      height: 32px;
      font-size: 20px;
      border: 1px solid $neon-blue;
      padding: 6px 0 0 6px;
      border-radius: 50%;
      color: $neon-blue;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &__wrapper-gradient-content {
    padding: 6rem .5rem;
    p {
      line-height: 1.875rem;
    }
  }

  &__fee_text {
    font-size: 1.1rem;
    line-height: 1.875rem;
  }

  &__fee_pay {
    font-size: 5rem;
    line-height: 1.25;
    color: $neon-blue;
  }

  .quote-slider__icon {
    width: auto;
  }

  &__image {
    margin: 0 auto;
  }

  &__wrapper {
    display: block;
    padding: 2.3rem 1.75rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__wrapper_km {
    color: $ultraViolet;
    background-color: $smoke;
  }

  &__meter {
    box-sizing: content-box;
    height: 20px;
    position: relative;
    margin: 10px 0;
    background: rgba(51,51,51,0.05);
    & > span {
      display: block;
      height: 100%;
      position: relative;
      overflow: hidden;
    }
    & > span.km {
      background: rgb(246,9,254);
      background: linear-gradient(90deg, rgba(246,9,254,1) 0%, rgba(136,122,231,1) 20%, rgba(108,149,225,1) 40%, rgba(50,207,214,1) 60%, rgba(14,244,206,1) 100%);
      width: 25%;
    }
    & > span.others {
      background: rgb(51,51,51);
      width: 85%;
    }
  }

  &__wrapper_others {
    border: 1px solid #bababa;
    border-radius: 8px;
    color: #333333;
    background-color: $smoke;
  }

  &__card {
    margin: 0;
    .collapse,.collapsing {
      background-color: $smoke;
      border-width: 0 1px 1px 2px;
      border-style: solid;
      border-color: $gray-92 $gray-92 $gray-92 $ultraViolet;
    }
  }

  &__card_header {
    padding: 0;
    .btn-link {
      text-decoration: none;
      padding: .75rem 1.25rem;
      background-color: $smoke;
      color: $ultraViolet;
      border-width: 1px 1px 0 2px;
      border-style: solid;
      border-color: $gray-92 $gray-92 $gray-92 $ultraViolet;
      &:after {
        content: "\f106";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        float: right;
      }
      &.collapsed {
        background-color: white;
        color: black;
        border: 1px solid #ebebeb;
        &:after {
          content: "\f107";
        }
      }
    }
  }
  &__card_body {
    max-width: 700px;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  @media (max-width: 340px) {
    &__fee_pay {
      font-size: 4rem;
    }
  }
  @include media-breakpoint-up(md) {

    &__title{
      font-size: 2rem;
      line-height: 3rem;
    }

    &__description {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    &__fee_text {
      font-size: 1.25rem;
    }

    &__wrapper-gradient-content {
      padding: 5rem 2.5rem;
      p {
        line-height: 1.875rem;
      }
    }

    &__image {
      margin: inherit;
    }

    &__fee_pay {
      font-size: 3.5rem;
      line-height: 1.25;
    }

  }

  @include media-breakpoint-up(lg) {

    &__title{
      font-size: 2.8rem;
      line-height: 3.5rem;
    }

    &__title_small {
      font-size: 2.5rem;
      line-height: 2.815rem;
    }

    &__fee_pay {
      font-size: 6.5rem;
    }

    &__description_medium {
      font-size: 1.25rem;
      line-height: 2.4rem;
    }

    &__description_big {
      font-size: 1.68rem;
      line-height: 2.4rem;
    }

    &__description_bigger {
      font-size: 1.7rem;
      line-height: 2.25rem;
    }

    &__wrapper-gradient-content {
      p {
        line-height: 1.875rem;
      }
    }

  }

  @include media-breakpoint-up(xl) {

    &__title{
      font-size: 3rem;
      line-height: 4rem;
    }

  }

}