/*!
 * Bootstrap v5.0 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Copyright 2011-2023 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


/*
  bootstrap/bootstrap - imports all bootstrap files
  replace this import with individual imports for each file you want to use
 */

/* font awesome */
@import "vendor/fontawesome/brands";
@import "vendor/fontawesome/regular";
@import "vendor/fontawesome/solid";
@import "vendor/fontawesome/fontawesome";
@import "abstracts/km-variables";

@import "vendor/bootstrap/bootstrap";

@import "abstracts/km-mixins";
@import "abstracts/km-utilities";

@import "layout/layout";
//@import "vendor/nouislider";
//@import "vendor/slick/slick";

@import "components/components";

@import "base/base";


