.table-custom {
  width: 930px;
  th {
    vertical-align: middle;
  }
  td, th {
    padding: 1rem 0.75rem;
    vertical-align: middle;
  }
  thead {
    tr {
      display: flex;
    }
    th {
      color: white;
      background-color: $ultraViolet;
      border: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      &:first-child {
        background-color: white;
      }
    }
  }
  tbody {
    font-size: 1rem;
    line-height: 1.5rem;
    tr {
      display: flex;
      flex: 1 1 auto;
    }
    th {
      background-color: #f9f9f9;
      width: 100%;
      font-weight: 500;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      text-align: center;
      sup {
        a{
          color: #2f80ed;
          padding-left: 3px;
        }
      }
    }
    td {
      width: 100%;
      color: $licorice;
      line-height: 1.75rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    p {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding-left: 0;
      text-align: left;
      li {
        padding-left: 1em;
        text-indent: -1em;
      }

      li:before {
        content: "\f00c";
        padding-right: 5px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: $ultraViolet;
      }
    }
  }

  @include media-breakpoint-up(md) {
    width: 100%;
  }
}

.plans {
  $self: &;
  p {
    font-size: 1rem;
    line-height: 1.7rem;
  }

  &__subtitle {
    font-size: 1.25rem;
    line-height: 2.4rem;
    color: $ultraViolet;
  }

  @include media-breakpoint-up(xl) {

    &__main_title{
      font-size: 3.5rem;
      line-height: 3.7rem;
    }
    &__title{
      font-size: 2.5rem;
      line-height: 2.45rem;
    }
  }
}