.hubspot-form {
  $self: &;

  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;

  &__title {
    text-align: center;
    color: $licorice;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin-bottom: 2rem;
  }

  &__subtitle {
    text-align: center;
  }

  &__form-wrap {
    max-width: 900px;
    margin: auto;

    .field {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      > * {
        flex: 1;
      }
    }

    h1{
      display:none;
    }
  }

  &_style_light{
    background: white;
  }

  &_style_light-grey{
    background: $smoke;
  }

  input, textarea {
    width: 100%;
    background-color: $smoke;
    border-color: $ultraViolet;
    border-width: 1px;
    font-size: .825rem;
    line-height: .825rem;
    padding: .75rem;
    //@extend .forms__input;
  }

  label {
    font-size: $font-size-sm;
    font-weight: bold;
  }

  .hs-form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    & > * {
      flex-basis: 100%;
    }

    .hs_submit{
      flex-basis: 100%;
      text-align: center;

      input{
        width: auto;
        padding: .8rem 2rem;

        &:hover{
          background-color: #5dfcde;
        }
      }
    }
  }

  .hs-fieldtype-booleancheckbox{

    ul{
      list-style: none outside none;
      padding-left: 0;
    }

    label{
      padding-left: 2rem;
      line-height: 1.5rem;
      position: relative;

      &::after {
        content: '';
        display: flex;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid black;
        position: absolute;
        top: 0;
        left: 0;
        font-family: 'Font Awesome 5 Free';
      }

      &.checked{
        &::after{
          content: '\f00c';
        }
      }

      input{
        opacity: 0;
        height: 0;
        width: 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {

    .hs-form {

      & > * {
        flex-basis: 48%;
      }

      .hs_submit {
        flex-basis: 100%;
        text-align: center;

        input {
          width: auto;
          padding: .8rem 2rem;
        }
      }

      .hs-fieldtype-booleancheckbox {
        padding-top: 1.7rem;
      }
    }
  }
  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-up(xl) {
  }

}