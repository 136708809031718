.list{
  $self: &;

  list-style: none;
  padding-left: 0;

  &__item{
    position: relative;
    font-weight: 700;
    margin-right: .6rem;
    padding-left: 1rem;


    &::before{
      font-family: 'Font Awesome 5 Free';
      position: absolute;
      font-weight: bold;
      left: 0.2rem;
      font-size: 0.4rem;
      top: 0.4rem;
    }
  }

  &_type_bullet{

    #{ $self }__item{


      &::before{
        content: '\f111';
        color: $neon-blue;
      }
    }
  }

  &_type_number{

    list-style: none;
    counter-reset: li;


    #{ $self }__item{

      &::before{
        content: counter(li);
        color: $neon-blue;
      }
    }
  }

  &_type_check{

    #{ $self }__item{

      padding-left: 1.6rem;

      &::before{
        content: '\f00c';
        color: $neon-blue;
        font-size: .8rem;
        top: .4rem;
      }
    }
  }

  &_large{
    font-size: 1.2rem;

    #{ $self }__item{
      padding-left: 1.4rem;
      margin-right: .8rem;

      &:before{
        font-size: 0.5rem;
        left: 0.3rem;
        top: 0.6rem;
      }
    }
  }

}