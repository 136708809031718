.container {
  &__blog {
    max-width: 750px;
    position: relative;
    margin-top: -120px;
    font-family: Arial;
  }
}

.wrapper-header-blog{
  padding: 0 5vw;
}

.text-blue {
  color: $blue-navy;
}

.wrapper-newsletter-blog {
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.1);
  border-radius: 15px 15px 15px 15px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px;
  margin: 40px 0;
}

.btn-category {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 10px;
  font-size: 12px;
}

.image-list-post {
  width: 100%;
  max-height: 173px;
  object-fit: cover;
  border-radius: 1rem 1rem 0 0;
}

.wrapper-prev-next{
  overflow: hidden;
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
    color: $blue-navy;
  }
}

.fa-arrow-next, .fa-arrow-prev {
  font-size: 1.5rem;
  color: $primary;
}

.fa-arrow-next {
  padding-left: 10px;
}

.fa-arrow-prev {
  padding-right: 10px;
}

.image-post {
  max-height: 400px;
}

.blog-pagination {
  .active>.page-link, .page-link.active {
    color: $white;
    border-color: $neon-violet;
    background-color: $neon-violet;
  }
}
