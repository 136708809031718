.navigation {
	$self: &;

	flex-grow: 1;
	justify-content: space-evenly;

  &__dropdown {
		position: relative;
		&.show {
	  	background-color: $white;

			#{ $self }__toggle-button{
				transform: rotate(180deg);
			}
		}
  }

	//&__toggle {
	//	&::after {
	//		content: '\f077' !important;
	//	}
	//}

	&__dropdown_padding {
		padding: 1rem 0;
  }

  &__toggle {
		display: inline-block;
		width: 100%;
  }

  &__list {
		border: 0;
		left: auto;
		min-width: 190px;
		right: 0;
		top: 100%;
		margin-top: 0;
		padding: 0;
  }

  &__item {
		font-weight: 400;
		padding: 0;
		text-transform: none;

		&_top-level{
			font-size: .8rem;
			font-weight: bold;
			flex: 1;
			padding: 0;
		}
  }

  &__link {
		display: inline-block;
		width: 100%;
		padding: 1rem;

		&:hover {
			color: $ultraViolet;
			background: white;
		}

		&.active {
			text-decoration: underline;
		}

		&_top-level{
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			padding-left: 1rem;
		}
  }

	&__toggle-button{
		position: absolute;
		right: 0px;
		top: 0px;
		background: none;
		border: none;
		color: #333;
		height: 60px;
		width: 80px;
	}

  @include media-breakpoint-up(lg) {

		&__dropdown {
			#{ $self }__toggle {
				&:after {
					content: '\f078' !important;
					font-family: 'Font Awesome 5 Free';
					padding-left: 5px;
					right: 1rem;
					position: initial;
					font-weight: bold;
				}
			}
		}

		&__dropdown:hover{
			#{ $self }__list {
				display: block;
			}

			#{ $self }__toggle {
				&:after {
					content: '\f077' !important;
				}
			}
		}


		&__link{

			&_top-level{
				padding-top: 2rem;
				padding-bottom: 2rem;
			}
		}

		&__list {
			background-color: $smoke;
			border-bottom: 5px solid $neon-blue;
			top: 100%;
			left: 0;
		}
  }

	@include media-breakpoint-up(xl) {
		padding-left: 5rem;
	}
}