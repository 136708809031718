.section-shape-top {
  top: -1px;
}

.section-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  svg {
    width: calc(230% + 1.3px);
    height: 100px;
    transform: translateX(-50%) rotateY(180deg);
    left: 50%;
    position: relative;
  }
  .section-shape-fill {
    fill: white;
    transform-origin: center;
    transform: rotateY(0deg);
  }
}

.section-shape-bottom {
  transform: rotate(180deg);
  bottom: -1px;
}

#footer-form {
  input:not([type="submit"]):not([type="checkbox"]){
    min-height: 47px;
  }
}

.title-footer {
  font-size: 30px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2em;
  @include media-breakpoint-up(lg){
    font-size: 45px;
  }
}

#go-to-form {
  background-color: $primary;
  color: white;
  cursor: pointer;
  position: fixed;
  left: 3vw;
  bottom: 40px;
  text-decoration: none;
  transition: all .3s ease;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 20px;
  z-index: 9999;
  &:hover {
    background-color: $secondary;
  }
}