.tabs-section {
  background-color: #f2f4f6;
}

.tabs-wrapper{
  border: 1px solid #e9e9e9;
  padding: 0;
}
.tabs-navigation {
  border: none;
  .nav-item {
    flex: 1;
    position: relative;
  }

  .nav-link {
    width: 100%;
    transition: color .15s ease-in-out,background-color .15s ease-in-out;
    color: $licorice;
    border: none;
    white-space: nowrap;
    &:hover {
      border: none;
    }
    &.active {
      background-color: $secondary;
      color: white;
    }
  }
}

.tabs-navigation-vertical {
  @include media-breakpoint-up(md) {
    .nav-link.active:after {
      position: absolute;
      content: "";
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $secondary;
      top: 50%;
      transform: translateY(-50%);
      right: -8px;
    }
  }
}