.forms {
  &__label {
    font-size: $font-size-sm;
    font-weight: bold;
  }

  &__input {
    background-color: $smoke;
    border-color: $primary;
    border-width: 1px;
    font-size: .825rem;
    line-height: .825rem;
    padding: .75rem;

    &[type="submit"]{
      @extend .buttons;
    }
  }
  &__input::placeholder {
    font-size: $font-size-sm;
  }
}

.rounded-30-left {
  border-radius: 30px 0 0 30px;
}

.rounded-30-right {
  border-radius: 0 30px 30px 0;
}