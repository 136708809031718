.image-rounded {
  border-radius: 300px 100px 100px 100px;
  box-shadow: 0 0 30px 0 rgba(0,0,0,0.12);
  &.image-rounded-bottom {
    border-radius: 100px 100px 300px 100px;
  }
  &.image-rounded-none {
    border-radius: 0;
    box-shadow: none;
  }
}