.component-text {
  $self: &;

  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  font-size: 1rem;

  &__content-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 3rem;
    margin-bottom: 1rem;

    > *{
      margin-bottom: 1rem;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &__title{
    color: $ultraViolet;
    font-weight: bold;
    line-height: 1.4;
    font-size: 1.625rem;
  }

  &__description{
    font-size: 1rem;
  }

  &__image-wrap{
    display: flex;
  }

  &__image_small{
    @extend .img-fluid;
  }

  &__link{
    align-self: flex-start;
  }

  &_style_pattern{
    #{ $self }__content-wrap{
      background: white;
    }
  }

  /* Layout Variants */
  &_layout_article{
    #{ $self }__content-wrap{
      @extend .col-12;
      @extend .col-md-10;
      @extend .offset-md-1;
      padding-top: 1rem;
    }
  }

  &_layout_image-right{
    #{ $self }__content-wrap{
      //@extend .col-md-7;
      @extend .col-lg-5;
    }

    #{ $self }__image-wrap{
      @extend .offset-lg-1;
    }
  }

  &_layout_image-left{
    #{ $self }__content-wrap{
      //@extend .col-md-7;
      @extend .col-lg-5;
      @extend .offset-lg-1;
    }

    #{ $self }__image-wrap{
      justify-content: center;
    }
  }

  &_layout_image-top{
    #{ $self }__content-wrap{
      @extend .col-md-12;
      @extend .col-lg-12;
      @extend .order-2;
      /*@extend .offset-lg-1;*/
    }

    #{ $self }__image-wrap{
      justify-content: center;
      max-width: 100%;
    }
  }

  &_layout_image-bottom{
    #{ $self }__content-wrap{
      @extend .col-md-12;
      @extend .col-lg-12;
      /*@extend .offset-lg-1;*/
    }

    #{ $self }__image-wrap{
      justify-content: center;
      max-width: 100%;
    }
  }

  &_layout_text-left-no-image {
    #{ $self }__content-wrap{
      @extend .col;
      @extend .col-sm-10;
      @extend .col-md-9;
      @extend .col-lg-7;
    }
  }

  &_layout_text-right-no-image {
    #{ $self }__content-wrap{
      @extend .col;
      @extend .col-sm-10;
      @extend .offset-sm-2;
      @extend .col-md-9;
      @extend .offset-md-3;
      @extend .offset-lg-5;
      @extend .col-lg-7;
    }
  }

  &_layout_text-center-no-image {
    text-align: center;

    #{ $self }__content-wrap{
      @extend .col;
      @extend .offset-md-1;
      @extend .col-md-10;
      @extend .offset-lg-2;
      @extend .col-lg-8;
    }

    #{ $self }__link{
      align-self: center;
    }
  }

  &__image_large{
    height: 100vw;
    width: 100vw;
    //max-height: 420px;
    //max-width: 420px;
    max-height: 576px;
    max-width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  /* End Layout Variants */
  &_style_pattern{
    @extend .pattern-background;

    #{ $self }__content-wrap{
      padding-top: 1rem;
    }
  }

  &_style_dark{
    background: $ultraViolet;
    color: white;

    #{ $self }__title{
      color: $neon-blue;
    }
  }

  &_style_light-grey{
    background: $smoke;
  }

  @include media-breakpoint-up(sm) {}

  @include media-breakpoint-up(md) {

    &__title{
      font-size: 2.5rem;
    }

    &__description{
      font-size: 1.25rem;
    }

    &__image-wrap{
      max-width: 632px;
      align-items: center;
    }
    /*
    &__image_large{
      position: absolute;
      left: unset;
      right: 0;
      //top: 50%;
      top: 0;
      translate: 0 -50%;
      max-width: 40vw;
      max-height: 40vw;
    }

     */
    /*
    &_layout_image-left{
      #{ $self }__row {
        justify-content: flex-end;
      }

      #{ $self }__content-wrap {
        order: 1;
      }

      #{ $self }__image_large{
        left: 0;
        right: unset;
      }
    }
*/
  }

  @include media-breakpoint-up(lg) {
    /*
    &__title{
      font-size: 2.5rem;
    }
*/
    &__content-wrap{
      margin-bottom: 0;
      padding-left: 1.5rem;
    }

    &__image-wrap{
      justify-content: flex-start;
    }

    &__image_large{
      position: absolute;
      left: unset;
      right: 0;
      max-height: unset;
      max-width: unset;
      width: 50%;
      top: 0;
      translate: unset;
      height: 100%;
    }

    &_layout_image-left,
    &_layout_image-right{
      min-height: 50vw;
    }

    &_layout_article{
      #{ $self }__content-wrap{
        padding-top: 2rem;
      }
    }
    /*
        &_layout_image-left{
          #{ $self }__image-wrap{
            justify-content: flex-end;
            align-items: center;
          }
        }
    */
    &_layout_image-left{
      #{ $self }__row {
        justify-content: flex-end;
      }

      #{ $self }__content-wrap {
        order: 1;
      }

      #{ $self }__image_large{
        left: 0;
        right: unset;
      }
      #{ $self }__image-wrap{
        justify-content: flex-end;
        align-items: center;
      }
    }

    &_layout_image-right{
      #{ $self }__image-wrap{
        justify-content: flex-start;
        align-items: center;
      }
    }

  }

  @include media-breakpoint-up(xl) {

    min-height: unset;
    /*
    &__description{
      font-size: 1.25rem;
    }
    */
    &_layout_text-center-no-image{
      #{ $self }__content-wrap{
        padding-right: 6rem;
        padding-left: 6rem;
      }
    }

    &__image_small{
      max-width: 632px;
      max-height: 632px;
    }
  }
}