#gritter-notice-wrapper {
  position: fixed;

  /*bottom: 0;
  right: 0;*/

  top: 0;
  right: 0;
  z-index: 9999;
  width: 350px;
  max-width: 100%;

  /*padding: 0 20px;*/
  padding: 10px 10px;
}

.gritter-item-wrapper {
  position: relative;
  padding: 30px 20px;
}

.gritter-title {
  display: none;
}

.gritter-item p {
  margin: 0;
}

.gritter-close {
  //&:extend( .close all );
  position: absolute;
  top: 3px;
  right: 6px;
  display: none;

  &:before {
    content: "\00D7";
  }
}