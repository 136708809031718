.logo-strip{
  $self: &;

  padding-top: 3rem;
  padding-bottom: 3rem;

  &__title{
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  &__slider{}

  &__slide{}

  &__slide_image{
    margin:0 auto;
  }

  @include media-breakpoint-up(sm) {}

  @include media-breakpoint-up(md) {}

  @include media-breakpoint-up(lg) {}

  @include media-breakpoint-up(xl) {}

}