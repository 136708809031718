.min-height-600 {
  min-height: 600px;
}

.min-height-800 {
  min-height: 800px;
}

.min-height-1024 {
  min-height: 1024px;
}

.mb-n-30 {
  margin-bottom: -30%;
  @include media-breakpoint-down(lg) {
    margin-bottom: 0;
  }
}

.z-99 {
  z-index: 99;
}
.wrapper-text-component {
  ul {
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 250 250' version='1.1'%3E%3Cpath d='M 222.500 22.219 C 212.942 29.318, 184.818 57.668, 171.628 73.500 C 143.208 107.610, 121.918 139.072, 96.227 184.928 C 93.187 190.355, 93.187 190.355, 91.168 186.928 C 68.086 147.739, 44.879 120, 35.175 120 C 18.149 120, 3.366 141.405, 16 147.766 C 40.212 159.956, 58.091 181.449, 86.164 232.112 C 97.405 252.399, 94.800 252.719, 105.426 229.750 C 142.412 149.801, 180.592 84.454, 222.175 29.928 C 233.779 14.713, 233.817 13.813, 222.500 22.219' fill='%236B48FF'/%3E%3C/svg%3E");
        padding-right: 10px;
      }
    }
  }

  ol {
    list-style: none;
    padding-left: 2.65rem;
    li {
      margin: 0 0 0.5rem 0;
      counter-increment: ol-counter;
      position: relative;

      &:before {
        content: counter(ol-counter);
        color: $white;
        position: absolute;
        --size: 1.75rem;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        background: $primary;
        border-radius: 50%;
        text-align: center;
      }
    }
  }
}

.wrapper-animated {
  position: relative;
  display: inline-block;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 60px);
    height: calc(100% + 20px);
    transform: translate(-50%,-50%);
    overflow: visible;
    path{
      stroke:$secondary;
      stroke-width:9;
      fill:none;
      opacity:0;
      stroke-dasharray:0 1500;
      transition:.3s;
      animation: headline-dash 1.5s linear forwards;
    }
  }
}

@keyframes headline-dash{
  0%{
    stroke-dasharray:0 1500;
    opacity:1
  }
  to{
    stroke-dasharray:1500 1500;
    opacity:1
  }
}
