.quote-client-slider {
  .carousel-inner {
    min-height: 550px;
    @include media-breakpoint-up(md) {
      min-height: 360px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 240px;
    }
  }
}