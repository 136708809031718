.section-title {
  color: $ultraViolet;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 2rem 0;

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
    line-height: 3rem;
  }
}