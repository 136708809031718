.header-top-bar {
  background-color: $neon-blue;

   &__item {
      font-family: $font-family-monospace;
      margin-left: 1rem;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        &:last-child {
          margin-right: 0.5rem;
        }
      }
   }

  &__link{
    &:hover{
      background: $smoke;
    }
  }

  @include media-breakpoint-up(md) {
    padding-right: 2rem;
  }
}

@include media-breakpoint-down(md) {
  .header-top-bar {

    &__item {
      margin: 0;
      display: block;
      padding: 1rem 0;
      width: 100%;
    }
  }
}