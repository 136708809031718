.slide-content {
  $self: &;


  padding-top: 3rem;
  padding-bottom: 1rem;

  &__slide{
    @extend .card;
    background: none;
    margin-bottom: 2rem;
  }

  &__headings{
    margin-bottom: 1rem;
  }

  &__content{
    @extend .card-body;
  }

  &__title{
    color: $ultraViolet;
    font-size: 1.625rem;
    font-weight: bolder;
    line-height: 1.5;
    margin-bottom: .5rem;
  }

  &__subtitle{
    font-size: .9rem;
  }

  &__slide-title{
    text-transform: uppercase;
    color: $ultraViolet;
  }

  &__slide-description{
    font-size: .7875rem;
  }

  &__image-wrap{
    position: relative;
    min-height: 200px;

    &:hover {
      #{ $self }__play-icon {
        background-color: rgba(8, 250, 204, .5);
      }
    }
  }

  &__play-icon{
    @extend .video-play-icon;
  }

  &_style_light{
    background: $smoke;
  }

  &_style_pattern{
    @extend .pattern-background;

    #{ $self }__slide {
      background: white;
    }
  }

  &_style_dark{
    background: $onyx;
    color: white;

    #{ $self }__title {
      color: $neon-blue;
    }

    #{ $self }__content {
      background: white;
    }

  }

  &_style_colourful{
    background: $ultraViolet;
    color: white;

    #{ $self }__title {
      color: $neon-blue;
    }

    #{ $self }__content {
      background: white;
    }
  }

  @include media-breakpoint-up(sm) {}

  @include media-breakpoint-up(md) {

    &__headings{
      margin-bottom: 1.5rem;
    }

    &__title{
      font-size: 2.25rem;
    }

    &__subtitle{
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }



  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-up(xl) {
  }

}