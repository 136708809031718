.social-icons{

  &__link{
    display: flex;
    border: 2px solid rgba(255,255,255,.5);
    border-radius: 50%;
    height: 36px;
    width: 36px;
    justify-content: center;
    align-items: center;
    @include transition(.2s);
    font-size: 1.85rem;
    color: $gray-800;


    &:hover{
      color: $secondary;
      background: rgba(255,255,255,.4);
      text-decoration: none;
    }
  }
}
