.cards{
  width: 100%;
}

.card {
  $self: &;

  border: 0;
  margin-bottom: 1rem;

  &__img{
    width: auto;
    max-width: 100%;
  }

  &__img_border-top {
	 max-width: fit-content;
  }

  &__img_rounded {
	 border-radius: 10rem;
  }

  &__body {
	  padding: 1rem 0;
  }

  &__title {
    color: $primary;
    font-weight: bold;
    margin-bottom: .75rem;
    text-transform: uppercase;
  }

   &__icon{
      align-self: flex-start;
      margin-bottom: 1.2rem;
   }

  &__img-wrap{
    position: relative;
  }

  &__plan {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .card-body {
      letter-spacing: 0.2px;
      @include media-breakpoint-down(md) {
        min-height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .card-title {
      color: $primary;
      font-size: 2em;
      line-height: 2.2rem;
      margin-bottom: 0.8rem;
    }
    .card-subtitle {
      color: $primary;
      font-size: 1.7rem;
      line-height: 1.5rem;
      margin-top: 0;
      margin-bottom: 0.9rem;
    }
    .card-smalltitle {
      color: $primary;
      font-size: 0.9rem;
    }
    .card-text {
      font-size: 0.8rem;
      line-height: 0.85rem;
    }
    .list-group {
      font-size: 1rem;
      letter-spacing: 0.2px;
      box-shadow: none;
      .list-group-item {
        min-height: 95px;
        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: center;
          //align-items: center;
          flex-direction: column;
          min-height: 160px;
        }
        &.hovered {
          background-color: #eee2ee;
        }
        &:last-child {
          margin-bottom: 1rem;
        }
      }
      .tooltip-icon {
        margin-top: 1rem;
      }
      @include media-breakpoint-down(lg) {
        .tooltip-icon {
          position: absolute;
          right: 5px;
          margin: 0;

          &:before {
            font-family: "Font Awesome 5 Free";
            content: "\f05a";
            font-style: normal;
            font-weight: 900;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            cursor: pointer;
            color: $secondary;
          }

          &:after {
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-top: 0.5rem solid $primary;
            top: 0;
            content: "";
            position: absolute;
            display: none;
            left: 0;
          }

          &:hover {
            margin-top: -0.5rem;
            padding-top: 0.5rem;

            &:before {
              color: $primary;
            }

            &:after {
              display: block;
            }

            .tooltip-content {
              display: block;
            }
          }

          .tooltip-content {
            display: none;
            background: $primary;
            bottom: 100%;
            box-shadow: 0 4px 12px 0 rgba(52, 4, 88, 0.15);
            color: white;
            font-size: 0.75rem;
            left: -1rem;
            line-height: 1.5em;
            padding: 1rem;
            position: absolute;
            width: 15rem;
            border-radius: 4px;
            z-index: 99;
          }

          .tooltip-content-left {
            right: -1rem;
            left: auto;
          }
        }
      }
    }
  }

  &__link{

    /* hover effect using filters for platform features icons */
    &:hover{
      #{ $self }__img_hover-effect_filter{
        filter: saturate(150%) hue-rotate(20deg);
      }
    }

    #{ $self }__img-wrap_hover-effect_background{
      &::after{
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .1s linear;
        background-color: $neon-blue;
      }
    }


    &:hover{

      #{ $self }__title{
        text-decoration: underline;
      }

      #{ $self }__img-wrap_hover-effect_background::after{
        opacity: .5;
      }
    }
  }

   &__modal-link{
     color: $smoke;
     background-color: rgba(0,0,0,.6);
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     border-radius: 50%;
     display:flex;
     justify-content: center;
     align-items: center;
     font-size: 1.25rem;
     opacity: 0;
     -webkit-transition: all .2s linear;
     -moz-transition: all .2s linear;
     -ms-transition: all .2s linear;
     -o-transition: all .2s linear;
     transition: all .2s linear;

     &:hover{
       opacity: 1;
       color: $smoke;
     }
   }

  /* CMS List card styles */
  &_cms-list{
	text-align: center;

    &:hover{
      text-decoration: none;
    }

    #{ $self }__title{
      font-size: 1.25rem;
    }
  }

   &_usps{
      margin-bottom: 2rem;

      #{ $self }__title{
         font-size: 1rem;
         line-height: 1.3rem;
      }

      #{ $self }__body{
         padding-top: 0;
         font-size: .8rem
      }

      #{ $self }__icon{
         height: 4rem;
         max-width: 5rem;
      }

     &:hover {
       text-decoration: none;

       #{ $self }__title{
         text-decoration: underline;
       }

       #{ $self }__icon{
         filter: saturate(150%) hue-rotate(20deg);
       }
     }

      @include media-breakpoint-up(md) {
         #{ $self }__body{
            font-size: 1rem
         }
      }
   }

  &_cms-list_2018_platform_features{
    align-items: center;

    #{ $self }__img{
      transition: all .2s;
      max-width: 7.5rem;
    }
  }
}

.card-address {
  border: 1px solid $gray-300;
  @include media-breakpoint-up(sm) {
	min-height: 450px;
  }
  @include media-breakpoint-up(md) {
	min-height: 410px;
  }
}
