.logo-strip{
  $self: &;

  padding-top: 3rem;
  padding-bottom: 3rem;

  .upper_title {
    margin-bottom: 0.45rem;
  }

  .title {
      font-size: 45px;
      line-height: 1.2em;
  }

  &__slider{}

  &__slide{}

  &__slide_image{
    margin:0 auto;
  }

  @include media-breakpoint-up(sm) {}

  @include media-breakpoint-up(md) {}

  @include media-breakpoint-up(lg) {}

  @include media-breakpoint-up(xl) {}

}

@media (max-width: 767px) {
  .logo-slider {
    .carousel-inner {
      .carousel-item{
        > div {
          display: none;
          &:first-child {
            display: block;
          }
        }
      }
    }
  }

}

.logo-slider {
  .carousel-inner {
    .carousel-item-next, .carousel-item.active, .carousel-item-prev {
      display: flex;
    }
  }
}

/* medium and up screens */
@media (min-width: 768px) {

 .logo-slider {

   [data-elements="3"] .carousel-item-end.active,
   [data-elements="3"] .carousel-item-next {
     transform: translateX(33.4%);
   }

   [data-elements="3"] .carousel-item-start.active,
   [data-elements="3"] .carousel-item-prev {
     transform: translateX(-33.4%);
   }

  [data-elements="4"] .carousel-item-end.active,
  [data-elements="4"] .carousel-item-next {
    transform: translateX(25%);
  }

  [data-elements="4"] .carousel-item-start.active,
  [data-elements="4"] .carousel-item-prev {
    transform: translateX(-25%);
  }

  [data-elements="5"] .carousel-item-end.active,
  [data-elements="5"] .carousel-item-next {
    transform: translateX(20%);
  }

  [data-elements="5"] .carousel-item-start.active,
  [data-elements="5"] .carousel-item-prev {
    transform: translateX(-20%);
  }

  }

  .logo-slider{
    .carousel-inner {
      .carousel-item-end, .carousel-item-start {
        transform: translateX(0);
      }
    }
  }

}