.pricing-upper-title {
  color: $secondary;
  text-transform: uppercase;
}

.card-pricing {
  border: 1px solid white;
  border-radius: 55px;
  overflow: auto;
  .heading {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-size: 24px;
    font-weight: 600;
    color: $white;
  }
  .wrapper-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    font-weight: 800;
    padding: 30px 0;
  }
  .amount {
    display: flex;
    line-height: 2.5rem;
  }
  ul {
    padding: 0;
    text-align: center;
    color: $licorice;
    li {
      list-style: none;
      padding-bottom: 1rem;
      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z'/%3E%3C/svg%3E");
        padding-right: 10px;
      }
    }
  }
}


