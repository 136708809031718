.cms-list{

  margin-top: 6rem;

  &__filters{
    display: flex;
    flex-flow: column;
    margin-bottom: 3rem;
  }

  &__filter_reset{
    order:10;
  }
}