.feature-upper-title {

}

.features-title {
  font-size: 45px;
}

.feature-wrapper {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 3% 3% 3% 3%;
  padding: 15% 10% 15% 10%;
  height: 100%;
  border-radius: 15px 15px 15px 70px;
  background-color: white;
  &.feature-rounded {
    border-radius: 55px 55px 55px 55px;
    padding: 50px 50px 50px 50px;
    margin: 0;
    .feature-title {
      font-size: 25px;
    }
  }
}

.feature-icon {
  font-size: 35px;
  color: $secondary;
  fill: $secondary;
  border-color: $secondary;
}

.feature-title {
  font-size: 20px;
  text-transform: capitalize;
  color: $primary;
}