.header-menu-bar {
  padding: 0;

  &__toggler {
    background-color: $neon-blue;
    height: 80px;
    width: 80px;
  }

  &__logo {
    max-height: 107px;
    width: auto;
  }
}

.header-nav-menu {
  display: flex;
  flex-wrap: wrap;
  margin: auto 0 auto auto;
  .menu-item {
    margin: 0 auto;
    &:first-child {
      margin: 0 auto 0 0;
    }
    &:last-child {
      margin: 0 0 0 auto;
    }
  }
}

.menu-item-gap:after {
  content:"";
  position:absolute;
  left:0;
  bottom: -0.5rem;
  display: none;
  vertical-align: bottom;
  width: 100%;
  min-width: 160px;
  height: 20px;
  background-color: transparent;
}

.menu-item {
  &:hover {
    &:after {
      display: inline-block;
    }
    .nav-menu-main:after {
      opacity: 1;
    }
    .nav-sub-menu {
      display: block;
      opacity: 1;
      visibility: initial;
      width: auto;
    }
    .nav-menu-main {
      opacity: 1;
    }
    .buttons__alt {
      background-color: $white;
      color: $primary;
      border: 1px solid $primary;
      font-weight: 600;
    }
  }
}

.nav-menu-main {
  display: flex;
  color: $white;
  padding: 25px 0;
  white-space: nowrap;
  flex-grow: 1;
  line-height: 1rem;
  font-size: 1rem;
  opacity: 0.8;
  &:hover{
    opacity: 1;
  }
}

.nav-sub-menu {
  opacity: 0;
  transition: opacity .6s linear;
  width: auto;
  top: 100%;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  min-width: fit-content;
  max-width: 1000px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fffeff;
  visibility: hidden;
  border-radius: 0.5rem;

  &.login-menu {
    min-width: 12em;
    & *{
      font-size: 1rem;
    }
  }

  li {
    border-width: 0;
  }
}


.nav-sub-menu-item {
  a{
    display: flex;
    padding: 15px 16px;
    flex-shrink: 0;
    font-size: 0.9375rem;
    color: $black-violet;
    white-space: nowrap;
    &:hover{
      font-weight: 600;
      color: $primary;
    }
  }
}

.sub-arrow {
  line-height: 1;
  padding: 10px 0 10px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  display: flex;
  align-items: center;
}

.fa-chevron-down {
  font-size: 0.7em;
}

.language-selector {
  height: 43px;
  &>a {
    padding: 14px 0;
  }
}

/*Mobile*/

.menu-toggle{
  color: white;
  margin-left: auto;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  padding: 0.25em;
  cursor: pointer;
  border: 0 solid;
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.element-screen-only {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.mobile-menu {
  position: absolute;
  z-index: 9997;
  max-height: 0;
  transition: max-height 0.6s,transform 0.6s;
  transform-origin: top;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.1);
  @include media-breakpoint-up(lg) {
    max-height: 0 !important;
  }
  li{
    border-width: 0;
    &:not(:last-child) {
      border-style: solid;
      border-bottom-width: 1px;
    }
  }
}

.mobile-menu-item {
  display: flex;
  padding: 16px;
  justify-content: center;
  color: $blue-navy;
  &:hover{
    color: $primary !important;
  }
}

.nav-sub-menu-mobile {
  display: none;
}

.navbar-toggler {
  border: 2px solid $white;
}

@include media-breakpoint-up(lg) {
  //.desktop-menu {
  //  display: flex;
  //}
  .menu-toggle {
    display: none;
  }
  .header-menu-bar {
    padding: 0;
    padding-left: 1.5rem;

    &__logo {
      margin-left: 0;
    }
  }
}