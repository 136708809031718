.notification {
  @extend .d-flex;
  @extend .justify-content-center;
  font-size: 1rem;
  padding: 1rem;
  padding-right: 3rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-right: 1rem;
  }

  /* notification_dark modifier */
  &_dark {
    background-color: $onyx;
    color: $white;
  }

  /* notification_purple modifier */
  &_purple {
    background-color: $ultraViolet;
    color: $white;
  }

  &__text {
    &_bold {
      font-weight: bold;
    }
    &_highlighted {
      color: $neon-blue;
      font-weight: bold;
    }
  }

  &__link {
    color: $neon-blue;
    font-family: $font-family-monospace;
    font-size: $font-size-sm;
    @extend .ms-1;
    text-transform: uppercase;

    &:hover, &:visited {
      color: $neon-blue;
    }
  }

  &__close {
    color: $white;
    position: absolute;
    right: 1rem;
    top: 33%;
  }
}