.wrapper-images {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
}

.upper-image-stories {
  width: 40%;
  align-self: end;
}

.image-stories {
  margin: -15% 0 0 0;
}

.wrapper-text-stories {
  padding-left: 10%;
  padding-right: 10%;
}

.heading-stories {
  font-size: 2.813rem;
  line-height: 2.5rem;
  margin-bottom: 20px;
  @include media-breakpoint-down(md){
    font-size: 2rem;
    line-height: 1.75rem;
  }
}