.btn-social {
  @include btn-social( $color-social, transparent );
  &:hover {
    color: $white;
  }
  width: 2.5em;
}

.btn-facebook {
  @include btn-social( $white, $gray-800 );
}

.btn-google {
  @include btn-social( $white, $gray-800 );
}

.btn-twitter {
  @include btn-social( $white, $gray-800 );
}

.btn-pinterest {
  @include btn-social( $white, $gray-800 );
}