.cms-highlighter {
  &__content {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $zindex-dropdown;
  }

  &__jumbotron {
    background: $highlighter-default;
    color: $white;
    left: 17%;
    margin-top: -240px;
    opacity: 0;
    padding: 1.4rem;
    position: absolute;
    transition: .3s;
    width: 66%;
    z-index: $zindex-modal;
  }
  &__jumbotron-show {
    margin-top: -56px;
    opacity: 1;
  }

  &__btn-close {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  &__modal {
    .modal-body-li {
      border-bottom: $modal-content-border-width solid $modal-content-border-color;
      padding: .7rem 0;

      .input-group-prepend {
        width: 30%;
        .input-group-text {
          width: 100%;
        }
      }

      .btn-outline-info {
        color: $highlighter-default;
        border-color: $highlighter-default;
        &:hover {
          color: $white;
          background-color: $highlighter-default;
          border-color: $highlighter-default;
        }
      }

      .btn-info {
        background-color: $highlighter-default;
        border-color: $highlighter-default;
        &:hover {
          background-color: darken($highlighter-default, 7.5%);
          border-color: darken($highlighter-default, 7.5%);
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__buttons {
    background-color: $highlighter-default;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: $primary;
      background-color: $white;
    }
  }
  &__buttons-close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }

}

.cms-highlighted {

  .cms-highlighter {
    &__content {
      display: block;
    }
  }

  &:hover {
    outline: 1px solid $highlighter-default;
    //box-shadow: inset 0 0 4px $info;
  }

  &_relative {
    position: relative;
  }

  &_small {
    .cms-highlighter__buttons {
      height: 22px;
      width: 10px;

      i {
        font-size: .75rem;
      }
    }
  }
}