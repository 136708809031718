.plugin-profile{
  $self: &;

  padding-top: 5rem;

  &__top-row{
    margin-bottom: 2rem;
  }

  &__logo{
    @extend .img-fluid;
    margin-bottom: 2rem;
  }

  &__title{
    color: $ultraViolet;
    font-size: 3rem;
    font-weight: bold;
    align-self: center;
  }

  &__info{
    margin-bottom: 2rem;
    order: 5;
  }

  &__overview{
    margin-bottom: 2rem;
  }

  &__info-title{
    color: $ultraViolet;
    font-weight: bold;
    font-size: 1.625rem;
  }

  &__rating{

    > i{
      color: $neon-blue;
    }
  }

  &__description{
    font-size: 1.2rem;
    margin-bottom: 2rem;
    order: 1;
  }

  &__images{
    padding: 3rem 0;
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .justify-content-around;
  }

  &__image{
    @extend .img-fluid;
  }

  &__video{
    padding: 3rem 0;
    display: flex;
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    &__top-row{
      flex-direction: row-reverse;
    }
  }

  @include media-breakpoint-up(lg) {
    &__description{
      order: unset;
    }

    &__info{
      order: unset;
    }
  }

}