.stat-cards {
  $self: &;

  padding-top: 3rem;
  padding-bottom: 3rem;

  &__headings{
    padding-bottom: 2rem;
  }

  &__title{
    color: #340458;
    font-weight: 700;
    line-height: 1.4;
    font-size: 1.625rem;
  }

  &__subtitle{
    font-size: .9rem;
  }

  &__card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  &__icon{
    margin-bottom: .5rem;
    max-width: 2.5rem;
  }

  &__highlight-text{
    font-size: 2.5rem;
    line-height: 2.5rem;
    background: linear-gradient(320deg, #FF00FF 0%, #08FACC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }

  &_style_light-grey {
    background: $smoke;
  }

  @include media-breakpoint-up(sm) {

    &__title{
      font-size: 2.5rem;
    }

    &__subtitle{
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-up(xl) {
  }

}